import React from 'react';
import style from '../scss/modules/hero.module.scss';

import Form from './Form';

function Hero() {
  return (
    <div className={style.container}>
      <div className={style.background}></div>
      <div className={style.backgroundGradient}></div>
        <div className={style.info}>
            <h1>Chapter Management - Simplified.</h1>
            <p>Learn why SilkStart MultiChapter is the top choice for organizations managing multiple chapters. Whether your organization is two chapters or two million, SilkStart will help you save money, create alignment, and control the quality of your oganization.</p>
           
        </div>

        <div id="contact-form" className={style.formContainer}>
 
            <Form />
      
        </div>

    </div>
  );
}

export default Hero;
