import React, {Component} from 'react';
import style from '../scss/modules/form.module.scss';
import emailjs from 'emailjs-com';
import success from '../images/svg/success.svg';

export const templateID = process.env.REACT_APP_TEMPLATE_ID;
export const userID = process.env.REACT_APP_USER_ID;


class Form extends Component {
    state = {
        name: '',
        email: '',
        website: '',
        phone: '',
        message: '',
        organization: '',
        submitted: false
    }

    componentDidMount() {
        let buttonId = 'submitFormButton';

        function trackingListener() {
            let capterra_vkey = 'b050e474f9be1c0b05e40b614fb893d6';
            let capterra_vid = '2085143';
            let capterra_prefix = (('https:' === document.location.protocol) ? 'https://ct.capterra.com' : 'http://ct.capterra.com');

            let ct = document.createElement('script');
            ct.type = 'text/javascript';
            ct.async = true;
            ct.src = capterra_prefix + '/capterra_tracker.js?vid=' + capterra_vid + '&vkey=' + capterra_vkey;

            let s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(ct, s);
        }

        let button = document.getElementById(buttonId);
        button.addEventListener('click', trackingListener);
    }

    handleSubmit(e) {
        e.preventDefault();

        const {
            name,
            email,
            website,
            phone,
            message,
            organization
        } = this.state;

        let templateParams = {
            from_name: name,
            from_email: email,
            from_website: website,
            from_organization: organization,
            to_name: 'SilkStart',
            from_phone: phone,
            message_html: message
        };

        emailjs.send(
            'amazon_ses',
            templateID,
            templateParams,
            userID
        );
        this.setState({submitted: true});
        this.resetForm();
    }

    resetForm() {
        this.setState({
            name: '',
            email: '',
            website: '',
            organization: '',
            phone: '',
            message: ''
        });
    }

    handleChange = (param, e) => {
        this.setState({[param]: e.target.value})
    }

    render() {
        if (this.state.submitted) {
            return (
                <div className={style.container}>
                    <div className={style.submitContainer}>
                        
                        <div className={style.submitTitle}>
                            <h1>Submitted</h1>
                        </div>

                            <img src={success} alt="Success icon" className={style.successIcon}/>

                            <p>Thank you for submitting the form!</p>
                            <p>Someone will contact you shortly.</p>
                    </div>

                </div>
            ); // TODO: make the "submitted" text look better
        } else {

            return (
                <div className={style.container}>
                    <div className={style.titleContainer}>
                        <h1>Request a Free Trial</h1>
                        <p>This month only - attend a product demo and get your chance to win a free iPad!</p>
                    </div>
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <label>
                            <input
                                className={style.input}
                                type="name"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange.bind(this, 'name')}
                                placeholder="Full Name *"
                                required
                            />
                        </label>
                        <label>
                            <input
                                className={style.input}
                                type="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange.bind(this, 'email')}
                                placeholder="Email Address *"
                                required
                            />
                        </label>
                        <label>
                            <input
                                className={style.input}
                                type="organization"
                                name="organization"
                                value={this.state.organization}
                                onChange={this.handleChange.bind(this, 'organization')}
                                placeholder="Organization name *"
                                required
                            />
                        </label>

                        <label>
                            <input
                                className={style.input}
                                type="text"
                                name="phone"
                                value={this.state.phone}
                                onChange={this.handleChange.bind(this, 'phone')}
                                placeholder="Phone *"
                                required
                            />
                        </label>

                        <button id="submitFormButton" variant="primary" type="submit">
                            Submit
                        </button>

                    </form>
                </div>
            );
        }
    }
}

export default Form;
