import React from 'react';
import Logo from '../images/silkstart-white-logo-multichapter.png';
import style from '../scss/modules/footer.module.scss';


function Footer() {
  return (
    <footer>
      <div className={style.container}>
        <div className={style.info}>
          <div className={style.content}>
            <h3>Whether it's two chapters, or two million.</h3>
            
            <div className={style.featuresContainer}>
              <div className={style.featuresItemLeft}>
                <p>Member Management</p>
                <p>Events</p>
                <p>Website CMS</p>
                <p>Intergration vs Full Website</p>
                <p>Mobile Friendly</p>
              </div>

              <div className={style.featuresItemMiddle}>
                <p>Groups</p>
                <p>Member Directory</p>
                <p>Email Marketing</p>
                <p>Job Board</p>
                <p>Invoicing &#38; Payments</p>
              </div>

              <div className={style.featuresItemRight}>
                <p>Smart Notifications</p>
                <p>Donations</p>
                <p>Custom Data Collection</p>
                <p>Membership Plans</p>
                <p>Ecommerce</p>
              </div>

            </div>

            
          </div>
        
        </div>
        <div className={style.footerLogo}>
          <img src={Logo} alt="SilkStart logo" /> 
        </div>
      </div>
    </footer>
  );
}

export default Footer;
