import React from 'react';
import Logo from '../images/silkstart-logo-multichapter.png';
import style from '../scss/modules/header.module.scss';


function Header() {
  return (
    <header>
      <div className={style.container}>
      <a href="https://silkstart.com/" target="_blank" rel="noopener noreferrer" >
           <img src={Logo} alt="SilkStart logo" />  
          </a>
        
      
      </div>
    </header>
  );
}

export default Header;
