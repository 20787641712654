import React from 'react';
import style from '../scss/modules/main.module.scss';
import Hero from './Hero';
import KeyItems from './KeyItems';



function Main() {
  return (
    <div className={style.container}>
      <Hero />
      <KeyItems />

    </div>
  );
}

export default Main;
