import React from 'react';
import style from '../scss/modules/keyitems.module.scss';
import paper from '../images/svg/information.svg';
import connect from '../images/svg/connectivity.svg';
import report from '../images/svg/report-b.svg';
import event from '../images/svg/event.svg';
import firewall from '../images/svg/firewall.svg';
import money from '../images/svg/money.svg';

function Key() {
  return (
    <div className={style.container}>
 
          <h2>Only SilkStart MultiChapter Provides You With All Of The Following Chapter Management Capabilities...</h2>
            <div className={style.itemContainer}>
                  <div className={style.info}>
                      <div className={style.wrap}>
                        <div className={style.icon}>
                          <img className={style.iconItem} src={paper} alt="Template chapter svg" />
                        </div>
                          <h4 className={style.iconTitle}>Template Chapter Sites</h4>
                          <p>Use professional, pre-approved templates to easily and quickly create new chapter websites</p>
                      </div>
                      <div className={style.wrap}>
                        <div className={style.icon}>
                        <img className={style.iconItem} src={connect} alt="Central HQ svg" />
                        </div>
                          <h4 className={style.iconTitle}>Central HQ Management</h4>
                          <p>Empower HQ administrators to control all chapters from a single dashboard - no need to navigate to individual sites</p>
                      </div>
                      <div className={style.wrap}>
                        <div className={style.icon}>
                        <img className={style.iconItem} src={report} alt="Report svg" />
                        </div>
                          <h4 className={style.iconTitle}>Rolled Up Reporting Across Chapters,<br/> Regions and Countries</h4>
                          <p>Run reports across the entire organization to see the big picture or zoom in on a specific chapter</p>
                      </div>
                      <div className={style.wrap}>
                        <div className={style.icon}>
                        <img className={style.iconItem} src={event} alt="Event promotion svg" />
                        </div>
                          <h4 className={style.iconTitle}>Event Cross Promotion</h4>
                          <p>Promote events across chapters to boost attendance</p>
                      </div>
                      <div className={style.wrap}>
                        <div className={style.icon}>
                        <img className={style.iconItem} src={firewall} alt="Firewall svg" />
                        </div>
                          <h4 className={style.iconTitle}>Isolated Chapter Views</h4>
                          <p>Give chapter administrators access and control of their chapter and member data only</p>
                      </div>
                      <div className={style.wrap}>
                        <div className={style.icon}>
                        <img className={style.iconItem} src={money} alt="Money svg" />
                        </div>
                          <h4 className={style.iconTitle}>Simplified Payments</h4>
                          <p>Flexibly provide payments processing on a chapter level, HQ level, or both - depending on your organizational structure</p>
                      </div>
                  </div>
              
             
            </div>
    
    </div>
  );
}

export default Key;
